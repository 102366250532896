import React from "react";
import {Button,Container} from "@material-ui/core";
import styles from "../commons/styles/HeaderStyles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import * as constants from "../../constants";
import {Navbar,Nav} from 'react-bootstrap';
import OKLogo from './assets/OKLogo.png';
import { useHistory } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

export const Header = () => {
  const classes = styles();
  const logoutLinkClasses = `${classes.navLink} ${classes.logoutLink}`;
  const isAuthenticated = useIsAuthenticated();
  let history = useHistory();

  const logout = () => {
    history.push('/logout');
  };

  return (
    <div id="header" className={classes.header}>
      <Container className={classes.container}>
        <Navbar collapseOnSelect expand="lg" className={classes.navbar}>
          <Navbar.Brand href="https://ui.ok.gov/en" className={classes.navbarBrand}>
            <img width="250" height="50" src={OKLogo} title="Oklahoma Employment Security Commission" alt="Oklahoma Employment Security Commission" data-testid="OKLogo"/>
          </Navbar.Brand>
          <Navbar.Toggle data-testid="navbar-toggler-icon" className={classes.navbarToggler} aria-controls="navbar-responsive-collapse" />
            <Navbar.Collapse id="navbar-responsive-collapse">
             <Nav className={classes.navbarNav}>
               <Nav.Link href= {constants.CONTACT_US_LINK} className={classes.navLink}>
                 <span>{constants.CONTACT}</span>
               </Nav.Link>
               <Nav.Link href= {constants.ABOUT_OESC_LINK} className={classes.navLink}>
                 <span>{constants.ABOUT}</span>
               </Nav.Link>
               <Nav.Link href= {constants.FAQ_LINK} className={classes.navLink}>
                 <span>{constants.FAQ_TEXT}</span>
               </Nav.Link>
               { isAuthenticated &&
                <Nav.Link data-testid="logout-link" onClick={logout} className={logoutLinkClasses}>
                  <span>{constants.LOGOUT}</span>
                </Nav.Link>
                }
             </Nav>
             { isAuthenticated &&
              <Button className={classes.logout}
                      onClick={logout}
                      data-testid="logout-button"
                      endIcon={<ExitToAppIcon/>}>
                {constants.LOGOUT}
              </Button>
             }
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};
