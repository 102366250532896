import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Consent from "../consent/Consent";
import * as constants from "../../constants";
import { useSelector } from "react-redux";
import UploadDocument from "../uploadDocument/UploadDocument";
import { Grid } from "@material-ui/core";
import UploadPortrait from "../uploadPortrait/UploadPortrait";
import UserVerified from "../UserVerified/UserVerified";
import IdentityStatus from "../uploadPortrait/IdentityStatus";
import store from "../../store/store"
import { logMessage } from "../../store/api/logMessage";
import { SOURCE_ANALYTICS } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  stepper: {
    paddingLeft: "0px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-18px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "-80px",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "-40px",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "-50px",
    },
  },
  stepperSecondary: {
    paddingLeft: "0px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-5px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "-57px",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "-27px",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "-34px",
    },
  },
}));

function getSteps(secondaryDocumentRequired) {
  return secondaryDocumentRequired
    ? constants.STEPPER_STEPS_WITH_SECONDARY
    : constants.STEPPER_STEPS;
}

function getStepContent(step, secondaryDocumentRequired) {
  let stepperContent = {
    primaryDocument: [
      <Consent />,
      <UploadDocument idType={constants.PRIMARY_ID} />,
      <UploadPortrait />,
      <UserVerified />,
    ],
    secondaryDocument: [
      <Consent />,
      <UploadDocument idType={constants.PRIMARY_ID} />,
      <UploadPortrait />,
      <UploadDocument idType={constants.SECONDARY_ID} />,
      <IdentityStatus />,
    ],
  };
  let stepperContentKey = secondaryDocumentRequired
    ? "secondaryDocument"
    : "primaryDocument";

  if (!stepperContent[stepperContentKey][step]) {
    const returnURL = sessionStorage.getItem("returnURL");
    if (returnURL) {
      store.dispatch(logMessage(SOURCE_ANALYTICS, LogUtil.INFORMATION, `Redirecting to return url - ${returnURL}`));
      window.location.href = returnURL;
      sessionStorage.removeItem("returnURL");
      return;
    } else {
      return "Coming soon...";
    }
  }

  return stepperContent[stepperContentKey][step];
}

export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const activeStep = useSelector((state) => state.userProgress.activeStep);
  const isSecondaryDocumentRequired = useSelector(
    (state) => state.userProgress.secondaryDocumentRequired
  );
  const steps = getSteps(isSecondaryDocumentRequired);

  return (
    <div className={classes.root}>
      <Grid item xs={12} md={5}>
        <Stepper
          data-testid="stepper"
          activeStep={activeStep}
          alternativeLabel
          className={
            isSecondaryDocumentRequired
              ? classes.stepperSecondary
              : classes.stepper
          }>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <div>
        <div>{getStepContent(activeStep, isSecondaryDocumentRequired)}</div>
      </div>
    </div>
  );
}
