import React, {useState} from "react";
import {Button, Container, Grid, Typography} from "@material-ui/core";
import styles from "./styles/desktopStyles";
import {Header} from "../commons/Header";
import {Footer} from "../commons/Footer";
import * as constants from "../../constants/desktop";
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import QRCode from "react-qr-code";
import { DEFAULT_RETURN_URL } from "../../constants/userVerified";

const Desktop = () => {
    const classes = styles();

    const [verifyInPerson, setVerifyInPerson] = useState(false);

    const toggleCantVerifyOnMobileButton = () => {
        setVerifyInPerson(!verifyInPerson);
    };

    const handlePostVerification = () => {
        window.location.href = sessionStorage.getItem("returnURL");
        sessionStorage.removeItem("returnURL");
    }

    const RETURN_URL = sessionStorage.getItem("returnURL") ?? DEFAULT_RETURN_URL;
    const AFTER_VERIFY_URL = sessionStorage.getItem("afterVerifyURL") ?? DEFAULT_RETURN_URL;

    return (
        <>
            <Header/>
            <Container className={classes.container}>
                <Typography
                    data-testid="verify-header"
                    variant={"h2"}
                    className={classes.header}
                >
                    VerifyOK
                </Typography>
                <Grid direction="column"
                      alignItems="center"
                      container spacing={0}
                      justifyContent="center"
                      className={classes.vokContainerWhite}
                >
                    <Grid
                        container direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <DesktopWindowsIcon className={classes.DesktopWindowsIcon}/>
                        </Grid>
                        <Grid item>
                            <TrendingFlatIcon className={classes.TrendingFlatIcon}/>
                        </Grid>
                        <Grid item>
                            <Grid item>
                                <PhoneIphoneIcon className={classes.PhoneIphoneIcon}/>
                                <CheckCircleIcon className={classes.CheckCircleIcon}/>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.onlineVerificationDescriptionGrid}
                    >
                        <Grid item>
                            <Typography variant="h6" data-testid="online-verification-header" className={classes.onlineVerificationDescriptionHeader}>
                                {constants.ONLINE_VERIFICATION_HEADER}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                {constants.PREVENT_FRAUD_DESCRIPTION}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                {constants.NEW_REQUIREMENT_DESCRIPTION}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.scanQRGrid}
                    >
                        <Grid container direction="row" justifyContent="space-around">
                            <Grid container direction="column" alignItems="flex-start" justifyContent="space-between" className={classes.scanQRTextGrid}>
                                <Grid item>
                                    <Typography variant="body2">
                                        {constants.SCAN_QR_CODE_TEXT}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" className={classes.vokWebLink} data-testid="base-url">
                                        {constants.UVOK_WEBSITE_LINK}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <QRCode value={constants.UVOK_WEBSITE_LINK + "?return_url=" + RETURN_URL + "&after_verify_url=" + AFTER_VERIFY_URL} size={90} data-testid="qr-code"
                                        fgColor="#2F80ED"/>
                            </Grid>
                        </Grid>
                    </Grid>

                    {sessionStorage.getItem("returnURL") && <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.postVerificationGrid}
                    >
                        <Grid item>
                            <Typography variant="body2" align="center">
                                {constants.POST_VERIFICATION_STEPS_TEXT}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                data-testid="go-to-dashboard-button"
                                color="primary"
                                variant="contained"
                                onClick={handlePostVerification}
                                className={classes.goToDashboardButton}
                            >
                                {constants.GO_TO_DASHBOARD_BUTTON_TEXT}
                            </Button>
                        </Grid>
                    </Grid>}
                </Grid>
                <Button
                    data-testid="cant-verify-on-mobile-button"
                    variant="outlined"
                    fullWidth
                    onClick={toggleCantVerifyOnMobileButton}>
                    {constants.CANT_VERIFY_ON_MOBILE_HEADER}
                </Button>
                {verifyInPerson &&
                <Grid
                    data-testid="Office-verification-steps"
                    container
                    direction="column"
                    alignItems="flex-start"
                >
                <Grid item>
                    <Typography variant="body2"
                    data-testid="visit-nearest-office-description">
                        <br/>
                        {constants.CONTACT_US_FOR_HELP}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={constants.CONTACT_US_LINK}
                        >
                            {constants.CONTACT_US}
                        </a>
                        {constants.FOR_FURTHER_ASSISTANCE}
                        {constants.VISIT_FREQUENTLY_ASKED_QUESTIONS}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={constants.FREQUENTLY_ASKED_QUESTIONS_LINK}
                        >
                            {constants.FREQUENTLY_ASKED_QUESTIONS}
                        </a>
                    </Typography>
                </Grid>
                </Grid>
                }
            </Container>
            <Footer/>
        </>
    );
};

export default Desktop;
